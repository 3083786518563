import { envs } from '~/src/utils';
import type { IConfiguration, IConfigurationPartial } from '~/src/types';

// Import all configurations
import defaultConf from './default';
import helsinki from './helsinki';
import espoo from './espoo';
import tupakkalakko from './tupakkalakko';
import terapianavigaattori from './terapianavigaattori';
import interventionavigaattori from './interventionavigaattori';
import neuvola from './neuvola';
import dokport from './dokport';
import terveysporttiVHH from './terveysportti';

function getConfig(organization: string): IConfigurationPartial {
  switch (organization.toLowerCase()) {
    case 'helsinki':
      return helsinki;
    case 'espoo':
      return espoo;
    case 'tupakkalakko':
      return tupakkalakko;
    case 'terapianavigaattori':
      return terapianavigaattori;
    case 'interventionavigaattori':
      return interventionavigaattori;
    case 'neuvola':
      return neuvola;
    case 'dokport':
      return dokport;
    case 'terveysportti':
      return terveysporttiVHH;
    default:
      return {};
  }
}

const clientConf: IConfigurationPartial = getConfig(envs.CONFIGURATION);

/**
 * Get config value from clientConf in defined there, otherwise get it from
 * defaultConf. If config value is an object do the same check for the first
 * level of keys but not deeper than that.
 *
 * @param {keyof IConfiguration} prop - Config property
 * @returns Configuration value
 */
function getConfigValue(prop: keyof IConfiguration) {
  const configValue = clientConf[prop];
  const defaultConfigValue = defaultConf[prop];

  // Return default config value if config value is not defined in clientConf
  if (!(prop in clientConf)) return defaultConfigValue;

  // Check if config value is object, if yes do a shallow merge
  if (typeof configValue === 'object' && typeof defaultConfigValue === 'object')
    return { ...defaultConfigValue, ...configValue };

  // Return primitive config value if defined
  if (configValue !== undefined) return configValue;

  return defaultConfigValue;
}

/* eslint-disable prettier/prettier */
export let startMenu = getConfigValue(
  'startMenu',
) as IConfiguration['startMenu'];
export const searchEnabled = getConfigValue(
  'searchEnabled',
) as IConfiguration['searchEnabled'];
export const colors = getConfigValue('colors') as IConfiguration['colors'];
export const header = getConfigValue('header') as IConfiguration['header'];
export const flowInit = getConfigValue(
  'flowInit',
) as IConfiguration['flowInit'];
export const translations = getConfigValue(
  'translations',
) as IConfiguration['translations'];
export const chat = getConfigValue('chat') as IConfiguration['chat'];
export const homeScreen = getConfigValue(
  'homeScreen',
) as IConfiguration['homeScreen'];
export const styles = getConfigValue('styles') as IConfiguration['styles'];
export const fonts = getConfigValue('fonts') as IConfiguration['fonts'];
export const siteTokenVerification = getConfigValue(
  'siteTokenVerification',
) as IConfiguration['siteTokenVerification'];
export const flowitemConf = getConfigValue(
  'flowitemConf',
) as IConfiguration['flowitemConf'];
export const radioStyles = getConfigValue(
  'radioStyles',
) as IConfiguration['radioStyles'];
/* eslint-enable prettier/prettier */

// Override some configuration values from environment variables
if (typeof envs.FF_ENABLE_CHAT === 'boolean')
  chat.enableChat = envs.FF_ENABLE_CHAT;
if (typeof envs.FF_ENABLE_PRO_CHAT_VIEW === 'boolean')
  chat.enableProChatView = envs.FF_ENABLE_PRO_CHAT_VIEW;
if (typeof envs.FF_START_MENU === 'string') startMenu = envs.FF_START_MENU;

// If chat is disabled and startMenu is default then replace it with no-chat
// to hide the chat button (but not replace if startMenyType is overridden)
if (
  chat.enableChat === false &&
  startMenu === 'default' &&
  typeof envs.FF_START_MENU !== 'string'
)
  startMenu = 'no-chat';
