import { useToken } from '@chakra-ui/react';

import { inputHeight } from '~/src/theme/constants';
import { styles } from '~/src/configurations';

const useSelectStyles = () => {
  const [primary500, brand500] = useToken('colors', [
    'primary.500',
    'brand.500',
  ]);

  return {
    container: (provided: any) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '9999px',
      borderColor: styles.inputBorderColor,
      borderWidth: styles.searchBorderWidth,
      padding: '.3rem .5rem',
      height: inputHeight,
      '&:hover': {
        borderColor: styles.inputBorderColor,
      },
      boxShadow: state.isFocused ? `0 0 0 1px ${brand500}` : 'none',
    }),
    menu: (provided: any) => {
      return {
        ...provided,
        boxShadow: 'none',
        position: 'relative',
      };
    },
    menuList: (provided: any) => {
      return {
        ...provided,
        maxHeight: 'none',
      };
    },
    option: (provided: any) => {
      return {
        ...provided,
        borderRadius: '.5rem',
        color: primary500,
      };
    },
    noOptionsMessage: (provided: any) => {
      return {
        ...provided,
        textAlign: 'left',
      };
    },
  };
};

export default useSelectStyles;
