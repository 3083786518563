import React from 'react';
import { Image } from '@chakra-ui/react';
import { FlowItem } from '~src/api';
import imageBankImages from '../../../images/image-bank/*';

// When adding a new image to the preDefinedImages object
// make sure image is added to the src/images/image-bank folder
const preDefinedImages: {
  [key: string]: [string, Array<string | null>?];
} = {
  'ear-infection': ['ear-infection.svg', [null, null, '100px']],
  'eye-infection': ['eye-infection.svg', [null, null, '100px']],
  flu: ['flu.svg', [null, null, '100px']],
  skin: ['skin.svg', [null, null, '100px']],
  pain: ['pain.svg', [null, null, '100px']],
  'stomach-ache': ['stomach-ache.svg', [null, null, '100px']],
  'alarming-symptom': ['alarming-symptom.svg'],
  'authentication-success': ['authentication-success.svg'],
  authentication: ['authentication.svg'],
  enigma: ['enigma.svg', [null, null, '100px']],
  feedback: ['feedback.svg', [null, null, '100px']],
  'flow-completed': ['flow-completed.svg'],
  generic: ['generic-card-header.svg'],
  'symptom-inquiry': ['symptom-inquiry.svg'],
  'symptom-intro': ['symptom-intro.svg'],
  'thank-you': ['thank-you.svg'],
};

const getImageData = (imgUrl: string) => {
  const imgData = preDefinedImages[imgUrl];
  if (imgData) {
    return {
      img: imageBankImages[imgData[0]],
      maxWidth: imgData[1],
    };
  }
  return;
};

const FlowItemImage: React.FC<{
  imgUrl: FlowItem['imgUrl'];
  maxWidth?: (string | null)[] | string;
}> = ({ imgUrl, maxWidth = [null, null, '200px', '250px'] }) => {
  if (!imgUrl) return <></>;
  const imgData = getImageData(imgUrl);
  return (
    <>
      <Image
        src={imgData?.img || imgUrl || ''}
        alt=""
        display={['none', null, 'block']}
        maxWidth={imgData?.maxWidth || maxWidth}
        py={[null, null, '4', '6']}
      />
    </>
  );
};

export { FlowItemImage };
