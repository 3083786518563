{
  "common": {
    "app_name": "Digivastaanotto",
    "app_organization": "Duodecim",
    "close": "Sulje",
    "continue": "Jatka",
    "search": {
      "label": "Hae",
      "placeholder": "Hae...",
      "noResults": "Ei tuloksia"
    },
    "charactersRemaining": "Characters remaining",
    "skipToContentLinkText": "Siirry suoraan sisältöön"
  },
  "errors": {
    "generic": {
      "title": "Unexpected Error",
      "message": "Oops, something went wrong. Try again later."
    },
    "network": {
      "title": "Connection Error",
      "message": "Make sure you are connected to internet."
    },
    "errorCard": {
      "title": "Oops, something went wrong!",
      "paragraph": "Content you are looking for is not available.",
      "ctaLabel": "Return to home page"
    },
    "validation": {
      "invalidEmail": "Sähköpostiosoite on virheellinen",
      "invalidPhone": "Puhelinnumero on virheellinen",
      "invalidValue": "Virheellinen syöte"
    }
  },
  "languageSelect": {
    "fi": "Suomeksi",
    "en": "In English",
    "sv": "På svenska"
  },
  "infoModal": {
    "title": "Ohjeita Digivastaanoton käyttöön",
    "paragraphContent": [
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Numquam magni ratione eius suscipit minima ipsa eos! Sint nisi maiores, molestias aut iste, repudiandae iure sed cum nostrum a rem. Labore.",
      "Another paragraph here..."
    ],
    "openButtonAriaLabel": "Avaa palveluinfo"
  },
  "intro": {
    "title": "Welcome to the Digital Reception",
    "paragraph": "By telling us some preliminary info you'll <1>get treatment in a heartbeat.</>",
    "linkText": "Tietoja digivastaanotosta"
  },
  "flowInit": {
    "title": "Mitä asiasi koskee?",
    "buttonLabels": {
      "healthIssue": "Health issue",
      "appointmentBooking": "Ajanvarausasia",
      "qAndA": "Kysymys tai muu asia"
    }
  },
  "authentication": {
    "callback": {
      "loading": "Tarkistetaan tietoja..."
    }
  },
  "healthIssueFlow": {
    "instructions": {
      "title": "Ohjeita",
      "firstParagraph": "Kysymme sinulta muutaman kysymyksen, jotta saamme kuvan tilanteestasi. Aloita kertomalla ikäsi ja sukupuolesi.",
      "highlightedContent": "<0>Jos tunnet olosi todella heikoksi tai epäilet olevasi vakavasti sairas, älä täytä oirearviota vaan ota yhteyttä päivystykseen tai soita 112.</0>",
      "lastParagraph": "Tarvitset kyselyn lopuksi sähköisen tunnistautumisen. Lisäohjeita löydät yläreunan kysymysmerkin takaa."
    },
    "basicInfo": {
      "title": "Perustiedot",
      "paragraphContent": [],
      "ageInput": {
        "label": "Ikä",
        "errorMessage": "Tarkista ikä"
      },
      "genderSelect": {
        "label": "Sukupuoli",
        "options": [
          {
            "value": "Male",
            "label": "Mies"
          },
          {
            "value": "Female",
            "label": "Nainen"
          }
        ]
      }
    },
    "symptomSelect": {
      "title": "Millaisia oireita sinulla on?"
    },
    "question": {
      "responseOptions": {
        "yes": "Kyllä",
        "no": "Ei",
        "unsure": "En tiedä"
      },
      "symptomSpanLabel": "Oireilun kesto",
      "additionalInfoButtonLabel": "Kerro lisätietoja halutessasi",
      "additionalInfoTextarea": {
        "label": "Lisätietoja",
        "placeholder": "Kirjoita lisätietoja"
      }
    },
    "alarmingSymptom": {
      "title": "Oireesi saattaa vaatia kiireellistä hoitoa",
      "paragraph": "Emme valitettavasti voi auttaa sinua etähoidossa. Hakeudu pikaisesti päivystysapuun, tai jos tilasi on vakava soita hätänumeroon.",
      "emergencyCarePrimaryLabel": "Valtakunnallinen päivystysapu",
      "emergencyCarePrimaryPhoneNumber": "116 117",
      "emergencyNumberLabel": "Hätänumero",
      "submitLabel": "Lopeta"
    },
    "generalDetails": {
      "title": "Oireiden kesto ja lisätiedot",
      "durationOptionsLabel": "Oireiden kesto",
      "additionalInfoTextarea": {
        "label": "Lisätiedot",
        "placeholder": "Anna halutessasi lisätietoja"
      }
    },
    "symptomOverview": {
      "title": "Tarkista vastauksesi",
      "paragraph": "Voit halutessasi palata muuttamaan valitsemaasi vastausta."
    },
    "authentication": {
      "title": "Tunnistautuminen",
      "paragraph": "Tunnistautumisen jälkeen et voi enää muuttaa vastauksiasi.",
      "mobileCertificate": "Mobiilivarmenne",
      "initError": "Tunnistautumisen aloitus ei onnistunut. Yritä uudelleen.",
      "retryError": "Tunnistautuminen ei onnistunut. Yritä uudelleen."

    },
    "authenticationSuccess": {
      "title": "Tunnistautuminen onnistui!",
      "name": "Nimi",
      "personalSecurityNumber": "Hetu",
      "phoneInput": {
        "label": "Matkapuhelinnumero",
        "placeholder": "040 123 4567"
      },
      "contactMethodSelect": {
        "label": "Miten haluat että sinuun otetaan yhteyttä?",
        "options": [
          {
            "value": "phoneCall",
            "label": "Vain puhelimitse"
          },
          {
            "value": "phoneCallOrSMS",
            "label": "Puhelimitse tai tekstiviestillä"
          }
        ]
      }
    },
    "confirm": {
      "title": "Kiitos vastauksistasi, valmista tuli!",
      "paragraph": "Sinuun otetaan yhteyttä mahdollisimman pian."
    }
  },
  "qAndAFlow": {
    "search": {
      "title": "Miten voimme auttaa?",
      "FAQ": "Usein kysytyt ja ajankohtaiset aiheet"
    }
  },
  "feedback": {
    "rating": {
      "label": "Miten hyvin pystyimme auttamaan sinua?",
      "options": {
        "1": "1 tähti",
        "2": "2 tähteä",
        "3": "3 tähteä",
        "4": "4 tähteä",
        "5": "5 tähteä"
      }
    },
    "input": {
      "labels": {
        "lowValue": "Voi harmi, miten olisimme voineet toimia paremmin?",
        "highValue": "Haluatko jättää vielä palautetta?"
      },
      "placeholder": "Anna vapaamuotoista palautetta"
    },
    "submitLabel": "Valmis"
  },
  "symptomDurationOptions": {
    "1": "Alle vuorokausi",
    "2": "1-3 vuorokautta",
    "3": "4-7 vuorokautta",
    "4": "Yli viikon tai pidempään"
  },
  "chat": {
    "user": {
      "pro": "Ammattilainen",
      "client": "Asiakas",
      "generic": "Käyttäjä"
    },
    "intro": {
      "title": "Chat-keskustelu",
      "firstParagraph": "Kun saat ohjauksen etähoitoon, saat chat-tunnisteen tekstiviestillä. Anna saamasi tunniste allaolevaan kentään ja pääset hoitoon.",
      "secondParagraph": "Mikäli sinulla ei ole chat-tunnistetta, siirry <1>Terveysongelma-hoitopolkuun</1>."
    },
    "init": {
      "inputLabel": "Chat-tunniste",
      "inputPlaceholder": "CID-1234-ABCD",
      "buttonLabel": "Aloita Chat"
    },
    "discussion": {
      "title": "Tervetuloa vastaanotolle!",
      "endDiscussion": "Lopeta keskustelu",
      "atYourService": "Sinua palvelee",
      "messageUnreadText": "Lukematta",
      "messageInputPlaceholder": "Kirjoita...",
      "messageAttachmentButtonText": "Lue liite",
      "endDiscussionAlert": {
        "title": "Oletko varma että haluat poistua keskustelusta?",
        "paragraph": "Käymäsi keskustelu katoaa, etkä voi palata siihen uudelleen.",
        "backButton": "Takaisin",
        "proceedButton": "Poistu"
      },
      "waitingForService": {
        "title": "Palvelemme sinua mahdollisimman pian",
        "paragraph": "Voit halutessasi aloittaa keskustelun odottaessasi asiakaspalvelijaamme."
      },
      "sessionEnded": {
        "message": "{{user}} poistui keskustelusta"
      }
    },
    "errors": {
      "generic": {
        "title": "Tapahtui virhe",
        "message": "Keskusteluun ei juuri nyt voida liittyä, yritä myöhemmin uudelleen."
      }
    }
  },
  "payment": {
    "title": "Maksaminen",
    "emailLabel": "Sähköpostiosoite",
    "doctorChat": "Doctor Chat",
    "doctorPhone": "Phone Doctor",
    "choosePaymentMethod": "Ole hyvä ja valitse maksutapa",
    "startpayment": "Siirry maksamiseen",
    "bank": "Verkkopankkimaksu",
    "creditcard": "Korttimaksut",
    "mobile": "Mobiilimaksu",
    "credit": "Maksa luotolla",
    "buttonText": "Maksa",
    "successMessage": "Maksu onnistui!",
    "failedMessage": "Maksu epäonnistui",
    "errorEmailTitle": "Maksutapahtuma epäonnistui",
    "errorEmailBody": "Maksutapahtuma epäonnistui. Kyselyn Id: {{answerId}}",
    "stateNotProcessed": "",
    "failedTitle": "Maksu keskeytyi",
    "failedDescription": "Maksu epäonnistui. Jos keskeytit maksutapahtuman, voit yrittää maksaa uudelleen. Jos maksu epäonnistui muusta syystä, ota yhteyttä asiakaspalveluumme.",
    "failedTryAgainButton": "Yritä uudelleen",
    "failedReturnLinkText": "Keskeytä",
    "callbackLoading": "Tarkistetaan maksutietoja...",
    "error": {
      "title": "Maksu epäonnistui",
      "description": "Maksua ei voitu luoda"
    },
    "serviceTermsModalTitle": "Palvelun käyttöehdot",
    "serviceTerms": "ja palvelun",
    "serviceTerms1": "käyttöehdot"
  },
  "calendly": {
    "showBookingModalButton": "Varaa aika",
    "noCalendlyEventUrl": "Emme löytäneet ajanvarauslinkkiä. Ota yhteyttä asiakaspalveluumme.",
    "errorEmailTitle": "Ajanvaraus epäonnistui",
    "errorEmailBody": "Ajanvaraus epäonnistui. Kyselyn Id: {{answerId}}"
  }
}
